import { motion } from "framer-motion";
import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import IntersectionBox from "../../animations/IntersectionBox";
import TrailingParagraph from "../../animations/TrailingParagraph";
import { fadeInFromRightWithDelay } from "../../animations/variants";
import GetQuoteSection from "../../components/GetQuoteSection";
import Materials from "../../components/industry/components/materials";
import PostProcessing from "../../components/industry/components/post_processing";
import Layout from "../../components/layout";
import MapSection from "../../components/MapSection";
import PlatformSection from "../../components/PlatformSection";
import ProcessDetails from "../../components/ProcessDetails";
import ManufacturingSolutions from "../../components/processes";
import QualitySection from "../../components/QualitySection";
import RequestCallback from "../../components/RequestCallback";
import SEO from "../../components/seo";

function CNCMachiningService() {
  const data = useStaticQuery(graphql`
    query {
      usp: strapi {
        services(where: { service: "cnc-machining" }) {
          service_usp {
            logo_title
            logo_image {
              image {
                url
              }
            }
          }
        }
      }
      strapi {
        services(where: { service: "forging" }) {
          banner_image {
            image {
              url
              formats
            }
          }
          title_description {
            description_text
            heading_text
          }
          end_to_end_services {
            description_text
            heading_text
          }
          sub_service {
            expanded_text
            service_name
            visible_description
            icon {
              image {
                url
                formats
              }
            }
            popup_image {
              url
              formats
            }
          }
          usp_header
          service_usp {
            logo_title
            logo_image {
              image {
                url
              }
            }
          }
          materials_heading
          material_type {
            material_description
            material_grades
            material_name
            material_image {
              image {
                url
              }
            }
          }
          post_processing_title
          pp_type {
            PP_title
            expanded_text
            visible_text
            PP_image {
              image {
                url
              }
            }
          }

          map_service {
            map_title
            map_description
            map_number_title
            map_image {
              url
            }
          }
          platform_title
          platform_advantage {
            description_text
            heading_text
          }
          quality_section {
            description
            heading
            Certifications_list
            graphic {
              image {
                url
              }
            }
          }
          rcb_service {
            form_title_description {
              description_text
              heading_text
            }
          }
          get_quote_services {
            description_text
            heading_text
          }
          seo {
            title
            meta_description
            meta_keywords
          }
          service_detailed {
            heading
            description
            graphic {
              image {
                url
              }
            }
          }
        }
      }
    }
  `);

  const node = data.strapi.services[0];

  return (
    <Layout>
      <SEO
        title={node.seo.title}
        description={node.seo.meta_description}
        keywords={node.seo.meta_keywords}
      />
      <div className='content'>
        <div className='service-info'>
          <div className='row service-row'>
            <div className='service-desc col-lg-5 col-md-5 col-sm-5 col-xs-12 '>
              <h1 className='service-desc-head'>
                <TrailingParagraph
                  paragraph={node.title_description.heading_text}
                />
              </h1>
              <p className='service-desc-exp'>
                <TrailingParagraph
                  paragraph={node.title_description.description_text}
                />
              </p>
              <IntersectionBox threshold={0.1}>
                <motion.button
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  className='clippy_button button-1'
                  onClick={() => {
                    window.open("/contact-us", "_blank");
                  }}>
                  Contact Us
                </motion.button>
              </IntersectionBox>
            </div>
            <div className='service-img col-lg-7 col-md-7 col-sm-7 col-xs-12'>
              <IntersectionBox variants={fadeInFromRightWithDelay(0.2)}>
                <img
                  src={node.banner_image.image.formats.medium.url}
                  alt='Metal Forging Services'
                />
              </IntersectionBox>
            </div>
          </div>
        </div>

        <ManufacturingSolutions
          title={node.end_to_end_services.heading_text}
          description={node.end_to_end_services.description_text}
          processes={node.sub_service}
        />

        <div class='service-features container-fluid'>
          <div
            class='row feature-row triggerAnimation animated'
            data-animate='fadeInUp'>
            <h2 className='usp-header'>
              <TrailingParagraph paragraph={node.usp_header} />
            </h2>
            {node.service_usp.map((service, index) => (
              <div class='column col-lg-3 col-md-3 col-sm-6 col-xs-6'>
                <IntersectionBox>
                  <div class='feature-icon'>
                    <img
                      src={service.logo_image.image.url}
                      alt={service.logo_title}
                    />
                  </div>
                  <p class='feature-desc'> {service.logo_title} </p>
                </IntersectionBox>
              </div>
            ))}
            {data.usp.services[0].service_usp
              .slice(2, 9)
              .map((service, index) => (
                <div class='column col-lg-3 col-md-3 col-sm-6 col-xs-6'>
                  <IntersectionBox>
                    <div class='feature-icon'>
                      <img
                        src={service.logo_image.image.url}
                        alt={service.logo_title}
                      />
                    </div>
                    <p class='feature-desc'> {service.logo_title} </p>
                  </IntersectionBox>
                </div>
              ))}
          </div>
        </div>
        <Materials
          title={node.materials_heading}
          materials={node.material_type}
        />
        <PostProcessing
          title={node.PP_title}
          pp={node.pp_type}></PostProcessing>
        <MapSection
          title={node.map_service.map_title}
          description={node.map_service.map_description}
          number={node.map_service.map_number_title}
        />
        <PlatformSection
          title={node.platform_title}
          tabs={node.platform_advantage}
        />
        <QualitySection
          title={node.quality_section.heading}
          description={node.quality_section.description}
          img={node.quality_section.graphic.image.url}
        />
        <ProcessDetails
          header={node.service_detailed.heading}
          description={node.service_detailed.description}
          img={node.service_detailed.graphic.image.url}
        />
        <RequestCallback
          title={node.rcb_service.form_title_description.heading_text}
          description={node.rcb_service.form_title_description.description_text}
        />
        <GetQuoteSection
          title={node.get_quote_services.heading_text}
          description={node.get_quote_services.description_text}
        />
      </div>
    </Layout>
  );
}

export default CNCMachiningService;
